<template>
  <div class="corporateInformation-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="打款信息" name="first">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" style="margin-top: 20px">
          <el-form-item prop="enterprise" label="选择企业" style="width: 100%">
            <el-col :xl="10">
              <el-select clearable v-model="ruleForm.enterprise" placeholder="请选择企业" style="width: 100%">
                <template v-for="(item,index) in companyList">
                  <el-option
                      :label="item.companyName"
                      :value="item.companyId"
                      :key="'info'+ index"
                  />
                </template>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item prop="settleOrder" label="结算订单" style="width: 100%">
            <el-col :xl="10">
              <el-button
                  style="background: #FFFFFF;border: 1px solid #999;color: #666666"
                  class="save"
                  type="info"
                  @click="toOrder()"
              >点击选择要结算订单
              </el-button
              >
            </el-col>
          </el-form-item>
          <el-form-item prop="way" label="打款方式" style="width: 100%">
            <el-col :xl="10">
              <el-select clearable v-model="ruleForm.way" placeholder="请选择打款方式" style="width: 100%">
                <template v-for="(item,index) in wayList">
                  <el-option
                      :label="item.name"
                      :value="item.index"
                      :key="'info'+ index"
                  />
                </template>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="收款人" prop="name">
            <el-col :xl="10">
              <el-input
                  placeholder="请输入收款人"
                  v-model="ruleForm.name"
                  clearable
                  maxlength="50"
                  show-word-limit
              />
            </el-col>
          </el-form-item>
          <el-form-item label="应付金额" prop="amountPayable">
            <el-col :xl="10">
              <el-input
                  placeholder="请输入应付金额"
                  v-model="ruleForm.amountPayable"
                  clearable
                  disabled
              />
            </el-col>
          </el-form-item>
          <el-form-item label="打款金额" prop="amountMoney">
            <el-col :xl="10">
              <el-input
                  @input="inputMaxL = /^\d+\.?\d{0,1}$/.test(ruleForm.amountMoney) ? null : ruleForm.amountMoney.length - 1"
                  placeholder="请输入打款金额"
                  v-model="ruleForm.amountMoney"
                  :maxlength="inputMaxL"
                  min="0"
                  clearable
                  @blur="toMoney"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="剩余款项" prop="remaining" v-if="isShow">
            <el-col :xl="10">
              <el-radio v-model=" ruleForm.remaining" label="0">抹零</el-radio>
            </el-col>
          </el-form-item>
          <el-form-item prop="coverImg" label="打款凭证">
            <p class="ma mb10" style="color: rgb(150, 150, 150)">
              图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过2M
            </p>
            <div>
              <el-upload :action="uploadUrl"
                         list-type="picture-card"
                         :class="{ disabled: uploadDisabled }"
                         :on-remove="handleRemove"
                         :on-success="handleSuccess"
                         :before-upload="beforeAvatarUploadImg"
                         :file-list="fileList"
                         :limit="1">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="打款时间" prop="dkDate">
            <el-col :xl="10">
              <el-date-picker
                  style="width: 100%"
                  v-model="ruleForm.dkDate"
                  type="datetime"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item prop="desc" label="备注">
            <el-col :xl="10">
              <el-input
                  placeholder="请输入备注"
                  v-model="ruleForm.desc"
                  type="textarea"
              />
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button
                class="save"
                type="primary"
                @click="submitForm()"
            >结算
            </el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        title="结算订单"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="80%"
        center
    >
      <div style="display: flex">
        <div style="display: flex">
          <div style="align-self: center;margin-right: 10px">订单编号</div>
          <div>
            <el-input
                placeholder="请输入订单编号"
                v-model="ordersNo"
                clearable
            />
          </div>
        </div>
        <div style="margin-left: 10px">
          <el-button
              class="save"
              type="primary"
              @click="search()"
          >搜索
          </el-button
          >
        </div>
      </div>
      <commonTable
          :tableData="orderList"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          @handleSelectionChange="handleSelectionChange"
          :currentPage="currentPage"
          :loading="loading"
          :total="total"
      >
        <template v-slot:table>
          <el-table-column type="selection"/>
          <el-table-column prop="ordersNo" align="center" label="订单编号" />
          <el-table-column prop="orderPrice" align="center" label="订单金额" />
        </template>
      </commonTable>
      <div style="display: flex;padding-bottom: 20px">
        <div style="margin-right: 20px">
          选中订单总额：￥{{moneyD}}
        </div>
        <div>
          选中订单总数：{{multipleSelection.length}}单
        </div>
      </div>
      <div style="padding: 20px 0;border-top: 1px solid #d0cfcf;text-align: center">
        <el-button
            style="width: 200px"
            class="save"
            type="primary"
            @click="toSubOrder"
        >确认
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {selectOrdersCompanyPageList,selectOrdersPageList,addPaymentVoucher} from "@/api/settlement";

export default {
  name:'settlementAll',
  components: {
    commonTable
  },
  data() {
    return {
      inputMaxL:10,
      multipleSelection:[],
      wayList: [{index: 0, name: '微信'}, {index: 1, name: '支付宝'}, {index: 2, name: '银行卡'}],
      companyList:[],
      orderList:[],
      total: 0, //总条数
      currentPage:1,
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      centerDialogVisible:false,
      activeName:'first',
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      fileList:[],
      ordersNo:'',
      ruleForm: {
        enterprise:"",
        settleOrder:"",
        way: "",
        name: "",
        amountPayable: "",
        amountMoney: "",
        remaining: "",
        desc: "",
        dkDate:"",
      },
      isShow:false,
      rules: {
        name: [{required: true, message: "请输入姓名", trigger: "blur"}],
        way: [{required: true, message: "请选择打款方式", trigger: "blur"}],
        enterprise: [{required: true, message: "请选择企业", trigger: "blur"}],
        amountMoney: [{required: true, message: "请输入打款金额", trigger: "change"}],
      },
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
    moneyD: function () {
      let DDmoney = 0;
      for (let i in this.multipleSelection) {
         DDmoney += Number(this.multipleSelection[i].orderPrice)
      }
      return DDmoney.toFixed(2)
    },
  },
  mounted() {
    var value = new Date()
    var year = value.getFullYear()
    var month = value.getMonth() + 1
    var day = value.getDate()
    var hour = value.getHours()
    var min = value.getMinutes()
    var sec = value.getSeconds()
    this.ruleForm.dkDate = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
    this.getCompanyList()
  },
  methods: {
    toMoney(){
      if (Number(this.ruleForm.amountMoney) != Number(this.ruleForm.amountPayable)) {
        this.isShow = true
      }else {
        this.isShow = false
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getCompanyList(){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
      }
      try {
        const result = await selectOrdersCompanyPageList(ajax);
        const {total, list} = result.data.pageInfo;
        this.companyList = list;
      } catch (error) {

      }
    },
    toSubOrder(){
      this.ruleForm.amountPayable = this.moneyD
      this.centerDialogVisible = false
    },
    async submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.multipleSelection.length == 0) {
            this.$message.error('请选择要结算的订单')
            return
          }else {
            for (let i in this.multipleSelection) {
              this.multipleSelection[i].orderPrice = parseInt(this.multipleSelection[i].orderPrice*100)
            }
            let data = {
              associationId: localStorage.getItem("associationId"),
              paymentType: this.ruleForm.way,
              payee: this.ruleForm.name,
              payAmount: parseInt(this.ruleForm.amountPayable*100),
              paymentAmount: parseInt(this.ruleForm.amountMoney*100),
              paymentVoucher:this.ruleForm.coverImg,
              paymentTime:this.renderTime(this.ruleForm.dkDate),
              remark:this.ruleForm.desc,
              ordersList:JSON.stringify(this.multipleSelection),
            };

            if (Number(this.ruleForm.amountPayable) == Number(this.ruleForm.amountMoney)){
              data.remainingAmount = 0
            }else {
              data.remainingAmount = 1
            }
            for (let i in this.companyList) {
              if (this.companyList[i].companyId == this.ruleForm.enterprise) {
                data.companyName = this.companyList[i].companyName
                data.companyId = this.companyList[i].companyId
              }
            }
            try {
              addPaymentVoucher(data, {showLoading: true});
              this.centerDialogVisible = false;
              this.$router.push({
                path: "../statements",
                query: {
                },
              });
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2M!");
      }
      return isLt2M;
    },

    async toOrder(){
      this.centerDialogVisible = true
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        companyId: this.ruleForm.enterprise,
        ifSettlement:1,
      }
      try {
        const result = await selectOrdersPageList(ajax);
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          list[i].orderPrice = (list[i].orderPrice/100).toFixed(2)
        }
        this.orderList = list;
      } catch (error) {

      }
    },
    search(){
      this.currentPage = 1;
      let data = {
        ordersNo: this.ordersNo,
      };
      this.toOrder(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        ordersNo: this.ordersNo,
      };
      this.toOrder(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        ordersNo: this.ordersNo,
      };
      this.toOrder(data);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/ .el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
  margin-left: 50px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

</style>

